<template>
  <div :class="$style.root">
    <SectionCard class="mb-0">
      <VTabs
        v-model="tabData"
        @change="onTabChange"
      >
        <VTab key="#users-organization" href="#users-organization">Пользователи подрядной организации</VTab>
        <VTab key="#users-invite" href="#users-invite">Заявки от внешних пользователей</VTab>
      </VTabs>
      <VTabsItems v-model="tabData" class="mt-4">
        <VTabItem key="users-organization" value="users-organization">
          <AccountUsersList
            :class="$style.orgList"
            :headers="organisationListHeaders"
            :items="organizationList.items"
            :count="organizationList.navCount"
            :page="page"
            :size="size"
            :loading="loading"
          />
        </VTabItem>
        <VTabItem key="users-invite" value="users-invite">
          <AccountUsersList
            :headers="[
          { text: 'ID пользователя', value: 'user.id', alias: 'userId', sortable: true },
          { text: 'Дата создания', value: 'user.dateCreate', alias: 'dateCreate', sortable: true },
          { text: 'ФИО', value: 'user.fio', alias: 'fio', sortable: false },
          { text: 'Должность', value: 'user.position', alias: 'position', sortable: false },
          { text: 'Статус', value: 'status.title', alias: 'status', options: values(statuses), sortable: false, multiple: false },
        ]"
            :items="inviteList.items"
            :count="inviteList.navCount"
            :page="page"
            :size="size"
            :loading="loading"
          />
        </VTabItem>
      </VTabsItems>
    </SectionCard>
    <VFooter v-if="canAdd && tabData === 'users-organization'" app>
      <div class="py-3 grow">
        <VBtn color="primary" class="mr-2" depressed @click="onCreate">Создать нового</VBtn>
        <VBtn color="primary" class="mr-2" depressed @click="onCreateFromDatabase">Создать из справочника</VBtn>
      </div>
    </VFooter>
  </div>
</template>

<script>

import SectionCard from '@/components/user/SectionCard/SectionCard.vue';
import AccountUsersList from '@/components/account-users/AccountUsers/components/AccountUsersList.vue';
import {mapActions, mapGetters} from 'vuex';
import {
  ACCOUNT_USERS_STATUSES,
} from '@/store/account-users/enums';
import {debounce, values, get, toString, omitBy, isNull, omit} from 'lodash-es';
import {ACCOUNT_APPLICATION_TYPES} from '@/store/account-application/enums';

export default {
  name: 'AccountUsers',
  components: { AccountUsersList, SectionCard },
  props: {
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    tab: { type: String, default: 'users-organization' },
    filter: { type: Object }
  },
  data() {
    return {
      tabData: null,
      filters: {
        'users-organization': {},
        'users-invite': {},
      }
    };
  },
  computed: {
    ...mapGetters({
      loading: 'accountUsers/pending',
      organizationList: 'accountUsers/list',
      inviteList: 'accountUsers/inviteList',
      hasRole: 'user/hasRole',
    }),
    organisationListHeaders() {
      return this.isAdminView ? [
        { text: 'ID пользователя', value: 'user.id', alias: 'userId', sortable: true },
        { text: 'ID аккаунту', value: 'accountId', alias: 'accountId', sortable: true },
        { text: 'Название организации', value: 'companyName', alias: 'companyName', sortable: true },
        { text: 'Дата создания', value: 'user.dateCreate', alias: 'dateCreate', sortable: true },
        { text: 'ФИО', value: 'user.fio', alias: 'fio', sortable: false },
        { text: 'Должность', value: 'user.position', alias: 'position', sortable: false },
        { text: 'Статус', value: 'status.title', alias: 'status', options: values(this.statuses), sortable: false, multiple: false },
      ] : [
        { text: 'ID пользователя', value: 'user.id', alias: 'userId', sortable: true },
        { text: 'Дата создания', value: 'user.dateCreate', alias: 'dateCreate', sortable: true },
        { text: 'ФИО', value: 'user.fio', alias: 'fio', sortable: false },
        { text: 'Должность', value: 'user.position', alias: 'position', sortable: false },
        { text: 'Статус', value: 'status.title', alias: 'status', options: values(this.statuses), sortable: false, multiple: false },
      ]
    },
    statuses() {
      return ACCOUNT_USERS_STATUSES
    },
    isAdminView() {
      return this.hasRole([get(ACCOUNT_APPLICATION_TYPES, 'ADMINISTRATOR.value'), get(ACCOUNT_APPLICATION_TYPES, 'ACCOUNT_OBSERVER.value'), ], 'account')
    },
    canAdd() {
      return this.hasRole([get(ACCOUNT_APPLICATION_TYPES, 'INITIATOR.value'), get(ACCOUNT_APPLICATION_TYPES, 'ACCOUNT_ADMINISTRATOR.value'), ], 'account')
    }
  },
  methods: {
    values,
    ...mapActions({
      fetchAccountUsersList: 'accountUsers/getAccountUsersList',
      fetchAccountInviteList: 'accountUsers/getAccountInviteList',
    }),
    onUpdateState: debounce(function () {
      this.update();
    }, 500),
    async update() {
      const { page, size, filter } = this;
      this.filters[this.tabData] = { page, size, ...filter };
      if (this.tabData === 'users-organization') {
        return this.fetchAccountUsersList({ page, size, filter: omit(filter, ['tab']) });
      } else if (this.tabData === 'users-invite') {
        return this.fetchAccountInviteList({ page, size, filter: { ...omit(filter, ['tab']), isActive: filter.isActive === 'true' } });
      }
    },
    onCreate() {
      this.$router.push({ name: 'account-users/AccountUsersCreateView' })
    },
    onCreateFromDatabase() {
      this.$router.push({ name: 'account-users/AccountUsersCreateView', query: { fromDatabase: true } })
    },
    onTabChange(tab) {
      const { query } = this.$route;
      if (toString(get(query, 'tab', '')) !== tab) {
        this.$router.push({ query: omitBy({ ...this.filters[tab], tab }, isNull) });
      }
    },
  },
  watch: {
    page: function () {
      this.onUpdateState();
    },
    size: function () {
      this.onUpdateState();
    },
    filter: {
      deep: true,
      handler: function () {
        this.onUpdateState();
      }
    },
  },
  beforeMount() {
    this.tabData = this.tab;
    this.onTabChange(this.tabData);
    this.update();
  }
}
</script>
<style lang="scss" module>
.root {
  :global {
    .v-tab {
     max-width: none;
    }
  }
}

.orgList {
  :global {
    th:nth-child(1) {
      width: 120px;
    }
    th:nth-child(3) {
      width: 155px;
    }
  }
}

</style>
