export const ACCOUNT_USERS_STATUSES = {
  NOT_CONFIRMED: {
    text: 'Не подтвержден',
    value: 'NOT_CONFIRMED',
  },
  ACTIVE: {
    text: 'Активен',
    value: 'ACTIVE',
  },
  INACTIVE: {
    text: 'Не активен',
    value: 'INACTIVE',
  },
  INVITE_WAITING: {
    text: 'Ожидает подтверждения',
    value: 'INVITE_WAITING',
  },
  INVITE_REJECTED: {
    text: 'Отклонён запрос на добавление',
    value: 'INVITE_REJECTED',
  },
  INVITE_BLOCKED: {
    text: 'Заблокирован',
    value: 'INVITE_BLOCKED',
  },
  REJECTED: {
    text: 'Отклонено добавление в аккаунт',
    value: 'REJECTED',
  },
}

export const ACCOUNT_USERS_DECISION = {
  ACTIVE: "ACTIVE",
  REJECT: "INVITE_REJECTED",
  BLOCK: "INVITE_BLOCKED",
}

export default {
  ACCOUNT_USERS_STATUSES, ACCOUNT_USERS_DECISION
}
